const Data = [
  {
    icon: "fa-mail-bulk",
    text: "Get In Touch",
    route: "/contact"
  },
  {
    icon: "fa-user-secret",
    text: "about",
    route: "/about"
  },
  {
    icon: "fa-laptop-code",
    text: "my work",
    route: "/gallery"
  },
  {
    icon: "fa-vector-square",
    text: "skills",
    route: "/skills"
  },
  {
    icon: "fa-home",
    text: "home",
    route: "/"
  }
]
export default Data;