import React from 'react';
const Stars = () => {
    return (
      <>
        <div id='stars'></div>
		    <div id='stars2'></div>
		    <div id='stars3'></div>
      </>
    );
};

export default Stars;